import { createHttpLink } from '@apollo/client/link/http';
import { ApolloClient, InMemoryCache, from, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from '@apollo/client/utilities';
import { getItem, LocalStorageKey } from 'services/persistentData';
import errorHandler from './errors';

const httpLink = createHttpLink({
  uri: window.config.API_GRAPHQL_ENDPOINT,
});

const wsLink = new GraphQLWsLink(createClient({
  url: window.config.API_GRAPHQL_ENDPOINT.replace(/^http(s)?/, 'ws$1'),
  connectionParams: () => {
    const token = getItem(LocalStorageKey.ACCESS_TOKEN);
    return { authorization: token ? `Bearer ${token}` : '' };
  }
}));

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getItem(LocalStorageKey.ACCESS_TOKEN);

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(errorHandler.onError);

const cache = new InMemoryCache();

export const client = new ApolloClient({
  link: from([
    errorLink,
    authLink,

    // Use Websockets for subscriptions, and HTTP otherwise.
    split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    ),
  ]),
  cache,
});
